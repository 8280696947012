@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: #474747;
}

.bg-image {
  background-image: url();
  background-image: linear-gradient(to bottom, #5b61a3ed 0%, #5b61a3ed 50%),
    url("../public/login-bg.png");
  --tw-bg-opacity: 0.5;
}

.day {
  color: orange;
}

.react-calendar__navigation {
  text-align: center;
  color: #434784;
}

.react-calendar__month-view__weekdays {
  color: #e04d4d;
  text-align: center;
}

abbr {
  text-decoration: none;
}

.react-calendar__tile--active {
  background-color: orange;
  color: white;
}

.password-bar > div div {
  height: 5px;
}
